/* eslint-disable no-sparse-arrays */
import React from 'react';
import { InstituicaoHelper } from './utils/InstituicaoHelper';

// Real routes
const LoginStrategy = React.lazy(() => import('./pages/Login/LoginStrategy'));
const Profile = React.lazy(() => import('./pages/Profile'));
const Temas = React.lazy(() => import('./pages/Temas/temas'));
const EditarTema = React.lazy(() => import('./pages/Temas/editarTema'));
const AgendarAtendimento = React.lazy(() =>
  import('./pages/Atendimento/agendarAtendimento'),
);

const Agenda = React.lazy(() => import('./pages/Profissional/agenda/agenda'));
const BuscarProfissional = React.lazy(() =>
  import('./pages/Profissional/buscarProfissional/buscarProfissional'),
);
const ListarAtendimentos = React.lazy(() =>
  import('./pages/ListarAtendimentos/listarAtendimentos'),
);
const EditarProfissional = React.lazy(() =>
  import('./pages/Profissional/manterProfissional/pageEditarProfissional'),
);
const VisualizarProfissional = React.lazy(() =>
  import(
    './pages/Profissional/visualizarProfissional/pageVisualizarProfissional'
  ),
);

const ListarProfissionais = React.lazy(() =>
  import('./pages/Profissional/manterProfissional/listarProfissionais'),
);

const DataRoom = React.lazy(() =>
  import('./pages/Profissional/dataRoom/dataRoom'),
);

const AgendarReuniao = React.lazy(() =>
  import('./pages/Atendimento/agendarReuniao/agendarReuniao'),
);

const Feedback = React.lazy(() =>
  import('./pages/Feedback/feedback'),
);

const ToolKit = React.lazy(() =>
  import('./pages/Profissional/toolKit/toolKit'),
);

const Assinatura = React.lazy(() =>
  import('./pages/Profissional/subscription/subscriptionPage'),
);

const FormPage = React.lazy(() =>
  import('./pages/Profissional/autodiagnostico/formPage'),
);

const Atas = React.lazy(() =>
  import('./pages/Profissional/dataRoom/atas'),
);

const ListarNotificacoes = React.lazy(() =>
  import('./pages/Notificacao/listarNotificacao'),
);

const EditarNotificacoes = React.lazy(() =>
  import('./pages/Notificacao/editarNotificacao'),
);

const AlterarSenha = React.lazy(() => import('./pages/AlterarSenha'));

// Sample routes
const Toaster = React.lazy(() =>
  import('./views/notifications/toaster/Toaster'),
);
const Tables = React.lazy(() => import('./views/base/tables/Tables'));

const Breadcrumbs = React.lazy(() =>
  // eslint-disable-next-line import/no-cycle
  import('./views/base/breadcrumbs/Breadcrumbs'),
);
const Cards = React.lazy(() => import('./views/base/cards/Cards'));
const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'));
const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'));
const BasicForms = React.lazy(() => import('./views/base/forms/BasicForms'));

const Jumbotrons = React.lazy(() =>
  import('./views/base/jumbotrons/Jumbotrons'),
);
const ListGroups = React.lazy(() =>
  import('./views/base/list-groups/ListGroups'),
);
const Navbars = React.lazy(() => import('./views/base/navbars/Navbars'));
const Navs = React.lazy(() => import('./views/base/navs/Navs'));
const Paginations = React.lazy(() =>
  import('./views/base/paginations/Pagnations'),
);
const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'));
const ProgressBar = React.lazy(() =>
  import('./views/base/progress-bar/ProgressBar'),
);
const Switches = React.lazy(() => import('./views/base/switches/Switches'));

const Tabs = React.lazy(() => import('./views/base/tabs/Tabs'));
const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'));
const BrandButtons = React.lazy(() =>
  import('./views/buttons/brand-buttons/BrandButtons'),
);
const ButtonDropdowns = React.lazy(() =>
  import('./views/buttons/button-dropdowns/ButtonDropdowns'),
);
const ButtonGroups = React.lazy(() =>
  import('./views/buttons/button-groups/ButtonGroups'),
);
const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'));
const Charts = React.lazy(() => import('./views/charts/Charts'));
// const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const CoreUIIcons = React.lazy(() =>
  import('./views/icons/coreui-icons/CoreUIIcons'),
);
const Flags = React.lazy(() => import('./views/icons/flags/Flags'));
const Brands = React.lazy(() => import('./views/icons/brands/Brands'));
const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'));
const Badges = React.lazy(() => import('./views/notifications/badges/Badges'));
const Modals = React.lazy(() => import('./views/notifications/modals/Modals'));
const Colors = React.lazy(() => import('./views/theme/colors/Colors'));
const Typography = React.lazy(() =>
  import('./views/theme/typography/Typography'),
);
const Widgets = React.lazy(() => import('./views/widgets/Widgets'));
const Users = React.lazy(() => import('./views/users/Users'));
const User = React.lazy(() => import('./views/users/User'));

const ListarFAQ = React.lazy(() => import('./pages/Faq/listarFAQ'));
const EditarFAQ = React.lazy(() => import('./pages/Faq/editarFAQ'));
const faqlist = React.lazy(() => import('./pages/Faq/list'));
const relatorio_consultoria = React.lazy(() =>
  import('./pages/Relatorios/consultorias'),
);
const lembretes = React.lazy(() => import('./pages/Lembretes/lembretes'));

const lembrete_resposta = React.lazy(() =>
  import('./pages/Lembretes/resposta'),
);

const ListarClientes = React.lazy(() =>
  import('./pages/Clientes/ListarClientes'),
);

const ManterCliente = React.lazy(() =>
  import('./pages/Clientes/ManterCliente'),
);

const avaliacao_consultoria = React.lazy(() =>
  import('./pages/Avaliacao/avaliar'),
);

const eventos = React.lazy(() => import('./pages/Eventos/eventos'));

// const eventos = React.lazy(() => import('./pages/Eventos/eventos'));

const dashboard = React.lazy(() => import('./pages/Dashboard/Dashboard'));

const agenda = React.lazy(() => import('./pages/Agenda/agenda/agenda'));

const routes = [
  {
    path: '/',
    exact: true,
    name: 'Login',
    component: LoginStrategy,
    isPrivate: false,
  },
  { path: '/temas', name: 'Temas', component: Temas, exact: true },
  {
    path: '/temas/adicionar',
    name: 'Adicionar tema',
    component: EditarTema,
    exact: true,
  },
  {
    path: '/temas/alterar/:id',
    name: 'Alterar tema',
    component: EditarTema,
    exact: true,
  },
  {
    path: '/profissionais/manter/listar',
    name: 'Profissionais',
    component: ListarProfissionais,
    exact: true,
  },
  {
    path: '/profissionais/manter/adicionar',
    name: 'Adicionar profissional',
    component: EditarProfissional,
    exact: true,
  },
  {
    path: '/notificacoes',
    name: 'Notificacoes',
    component: ListarNotificacoes,
    exact: true,
  },
  {
    path: '/notificacoes/manter',
    name: 'Manter notificacao',
    component: EditarNotificacoes,
    exact: true,
  },
  {
    path: '/notificacoes/manter/:idNotificacao',
    name: 'Editar notificacao',
    component: EditarNotificacoes,
    exact: true,
  },
  {
    path: '/atendimento/agendarAtendimento/:profissionalId',
    name: 'Agendar atendimento',
    component: AgendarAtendimento,
    exact: true,
  },
  {
    path: '/profissional/agenda',
    name: 'Agenda',
    component: Agenda,
    exact: true,
  },
  {
    path: '/profissional/buscar',
    name: 'Buscar profissional',
    component: BuscarProfissional,
    exact: true,
  },
  {
    path: '/atendimento/listar',
    name: 'Listar atendimentos',
    component: ListarAtendimentos,
    exact: true,
  },
  {
    path: '/profissional/editar/:idProfissional',
    name: 'Editar profissional',
    component: EditarProfissional,
    exact: true,
  },
  {
    path: '/profissional/visualizar/:idProfissional?',
    name: 'Visualizar profissional',
    component: VisualizarProfissional,
    exact: true,
  },
  {
    path: '/alterarSenha',
    name: 'Alterar senha',
    component: AlterarSenha,
    exact: true,
  },
  { path: '/profile', name: 'Profile', component: Profile },
  {
    path: '/Faq/listarFAQ',
    name: 'Listar FAQ',
    component: ListarFAQ,
    exact: true,
  },
  {
    path: '/faq/manter',
    name: 'Editar FAQ',
    component: EditarFAQ,
    exact: true,
  },
  {
    path: '/faq/manter/:idFaq',
    name: 'Editar FAQ',
    component: EditarFAQ,
    exact: true,
  },
  {
    path: '/faq/list',
    name: 'Editar FAQ',
    component: faqlist,
    exact: true,
  },
  // { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/theme', name: 'Theme', component: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/base', name: 'Base', component: Cards, exact: true },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  { path: '/base/cards', name: 'Cards', component: Cards },
  { path: '/base/carousels', name: 'Carousel', component: Carousels },
  { path: '/base/collapses', name: 'Collapse', component: Collapses },
  { path: '/base/forms', name: 'Forms', component: BasicForms },
  { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  { path: '/base/navbars', name: 'Navbars', component: Navbars },
  { path: '/base/navs', name: 'Navs', component: Navs },
  { path: '/base/paginations', name: 'Paginations', component: Paginations },
  { path: '/base/popovers', name: 'Popovers', component: Popovers },
  { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
  { path: '/base/switches', name: 'Switches', component: Switches },
  { path: '/base/tables', name: 'Tables', component: Tables },
  { path: '/base/tabs', name: 'Tabs', component: Tabs },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  { path: '/buttons', name: 'Buttons', component: Buttons, exact: true },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  {
    path: '/buttons/button-dropdowns',
    name: 'Dropdowns',
    component: ButtonDropdowns,
  },
  {
    path: '/buttons/button-groups',
    name: 'Button Groups',
    component: ButtonGroups,
  },
  {
    path: '/buttons/brand-buttons',
    name: 'Brand Buttons',
    component: BrandButtons,
  },
  { path: '/charts', name: 'Charts', component: Charts },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/brands', name: 'Brands', component: Brands },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Alerts,
    exact: true,
  },
  { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
  { path: '/notifications/badges', name: 'Badges', component: Badges },
  { path: '/notifications/modals', name: 'Modals', component: Modals },
  { path: '/notifications/toaster', name: 'Toaster', component: Toaster },
  { path: '/widgets', name: 'Widgets', component: Widgets },
  { path: '/users', exact: true, name: 'Users', component: Users },
  { path: '/users/:id', exact: true, name: 'User Details', component: User },
  {
    path: '/Relatorios/consultorias',
    name: 'Consultorias',
    component: relatorio_consultoria,
    exact: true,
  },
  {
    path: '/Lembretes/lembretes',
    name: 'Lembretes',
    component: lembretes,
    exact: true,
  },
  {
    path: '/Lembretes/resposta',
    name: 'Lembretes_resposta',
    exact: true,
    component: lembrete_resposta,
    isPrivate: false,
  },
  {
    path: '/Clientes/ListarClientes',
    name: 'Clientes',
    exact: true,
    component: ListarClientes,
  },
  {
    path: '/Clientes/ManterCliente',
    name: 'Clientes',
    exact: true,
    component: ManterCliente,
  },
  {
    path: '/Clientes/ManterCliente/:id',
    name: 'Clientes',
    exact: true,
    component: ManterCliente,
  },
  {
    path: '/Avaliacao/avaliar',
    name: 'avaliacao_consultoria',
    exact: true,
    component: avaliacao_consultoria,
    isPrivate: false,
  },
  {
    path: '/Eventos/eventos',
    name: 'eventos',
    exact: true,
    component: eventos,
    isPrivate: true,
  },
  ,
  // {
  //   path: '/Eventos/eventos',
  //   name: 'eventos',
  //   exact: true,
  //   component: eventos,
  //   isPrivate: true,
  // },
  {
    path: '/Dashboard/dashboard',
    name: 'dashboard',
    exact: true,
    component: dashboard,
    isPrivate: true,
  },
  {
    path: '/Agenda/AgendaPrincipal',
    name: 'agenda',
    exact: true,
    component: agenda,
    isPrivate: true,
  },
  {
    path: '/profissionais/dataroom',
    name: 'DataRoom',
    component: DataRoom,
    exact: true,
  },

  {
    path: '/atendimento/agendarReuniao',
    name: 'AgendarReuniao',
    component: AgendarReuniao,
    exact: true,
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback,
    exact: true,
  },
  {
    path: '/profissionais/toolkit',
    name: 'ToolKit',
    component: ToolKit,
    exact: true,
  },
  {
    path: '/profissionais/subscription',
    name: 'Assinatura',
    component: Assinatura,
    exact: true,
  },
  {
    path: '/profissionais/autodiagnostico',
    name: 'Autodiagnostico',
    component: FormPage,
    exact: true,
  },
  {
    path: '/profissionais/atas',
    name: 'Atas',
    component: Atas,
    exact: true,
  },
  ,
  ,
];

routes.forEach(route => {
  if (typeof route.isPrivate === 'undefined') {
    // eslint-disable-next-line no-param-reassign
    route.isPrivate = true;
  }
});

export default routes;
