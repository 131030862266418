import React from 'react';
import { TheContent, TheSidebar, TheFooter, TheHeader } from './index';

import { useAuth } from '../hooks/auth';

const TheLayout = () => {
  const { user } = useAuth();

  return (
    <div className="c-app c-default-layout">
      {user && <TheSidebar />}
      <div className="c-wrapper">
        {user && <TheHeader />}
        <div className="c-body">
          <TheContent />
        </div>
        {user && <TheFooter />}
      </div>
    </div>
  );
};

export default TheLayout;
