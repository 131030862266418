import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CHeader, CToggler, CHeaderBrand, CHeaderNav } from '@coreui/react';

import { TheHeaderDropdown, TheHeaderNotificacoes } from './index';

const TheHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector(state => state.sidebarShow);

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow)
      ? false
      : 'responsive';
    dispatch({ type: 'set', sidebarShow: val });
  };

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow)
      ? true
      : 'responsive';
    dispatch({ type: 'set', sidebarShow: val });
  };

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />

      {!sidebarShow && (
        <CHeaderBrand className="ml-md-3 d-lg-block" to="/">
          <div className="c-sidebar-brand-center" />
        </CHeaderBrand>
      )}

      {!!sidebarShow && (
        <CHeaderBrand className="ml-md-3 d-block d-sm-block d-lg-none" to="/">
          <div className="c-sidebar-brand-center" />
        </CHeaderBrand>
      )}

      <CHeaderNav className="mr-auto" />

      <CHeaderNav className="px-3">
        <TheHeaderNotificacoes />
        <TheHeaderDropdown />
      </CHeaderNav>
    </CHeader>
  );
};

export default TheHeader;
