import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react';

import { useAuth } from '../hooks/auth';
import menuGenerator from '../utils/menuGenerator';

const TheSidebar: React.FC = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const show = useSelector(state => state.sidebarShow);
  const { user } = useAuth();
  const [navigation, setNavigation] = useState([] as any[]);

  useEffect(
    function carregarMenu() {
      setNavigation(menuGenerator(user.funcionalidades));
    },
    [user.funcionalidades],
  );

  return (
    <CSidebar
      show={show}
      onShowChange={(val: any) => dispatch({ type: 'set', sidebarShow: val })}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <div className="c-sidebar-brand-full" />
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
