import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { registerLocale } from 'react-datepicker';
import { Provider } from 'react-redux';
import { useEffect } from 'react';
import AppProvider from './hooks';

import store from './store.js';
import './scss/style.scss';
import TheLayout from './containers/TheLayout.js';
import { i18nextToDateFnsLocale } from './utils/dateService';
import i18n from './i18n/i18n.js';

const App: React.FC = () => {
  useEffect(function carregarCssDoCliente() {
    registerLocale(i18n.language, i18nextToDateFnsLocale(i18n.language));
    const { head } = document;
    const link = document.createElement('link');

    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = `/css/${window.location.hostname}`;

    head.appendChild(link);

    return () => {
      head.removeChild(link);
    };
  }, []);

  return (
    <Router>
      <Provider store={store}>
        <AppProvider>
          <TheLayout />
        </AppProvider>
      </Provider>
    </Router>
  );
};

export default App;
