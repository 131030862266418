export class InstituicaoHelper {
  static isCNDL(): boolean {
    return window.location.href.includes('cndl');
  }

  static isSebraeMG(): boolean {
    return window.location.href.includes('sebraemg');
  }

  static isSebraeGO(): boolean {
    return window.location.href.includes('sebraego');
  }

  static isMentoria(): boolean {
    return window.location.href.includes('amentoriaonline2');
  }

  static isBinMinas(): boolean {
    return window.location.href.includes('binminas');
  }

  static isSmartBoard(): boolean {
    return (
      window.location.href.includes('smartboard') ||
      window.location.href.includes('localhost')
    );
  }
}
