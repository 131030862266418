import React, { createContext, useCallback, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import api from '../services/api';

interface User {
  id: string;
  nome: string;
  email: string;
  urlAvatar: string;
  timezone: string;
  funcionalidades: string[];
  comSenhaAutomatica: boolean;
  perfilIncompleto: boolean;
  pago: boolean;
  dataAssinatura?: Date;
  idProfissional: string | undefined;
  conselho: string;
  tipoUsuario: 'Cliente' | 'Profissional'; // TODO: nem todos os usuarios serão esses tipos
}

interface AuthState {
  token: string;
  user: User;
}

interface AuthContextData {
  user: User;
  usuarioLogadoPeloLembreDeMim(): User | undefined;
  signIn(
    lembreDeMim: boolean,
    apiPostFunction: () => Promise<AxiosResponse>,
  ): Promise<void>;
  signOut(): void;
  signOutUsuarioLembrado(): void;
  converteUsuarioLembradoEmUsuarioLogado(): User | undefined;
  updateUser(user: User): void;
  forgotPassword(email: string, instituicao?: string): Promise<AxiosResponse>;
  refreshToken(): Promise<void>;
  usuarioLogadoContemPermissaoAdministrador(): boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

type RetryRequestConfig = AxiosRequestConfig & {
  retry?: boolean;
};

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Connsult:token');
    const user = localStorage.getItem('@Connsult:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return {
        token,
        user: JSON.parse(user),
      };
    }
    return {} as AuthState;
  });

  api.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      const originalRequest: RetryRequestConfig = error.config;
      if (error.response?.status === 200) {
        return Promise.resolve(error.response);
      }

      if (
        error.response?.status === 401 &&
        originalRequest?.url?.indexOf('/login') !== -1
      ) {
        return Promise.reject(error);
      }
      if (error.response?.status === 401 && !originalRequest?.retry) {
        originalRequest.retry = true;
        return api.post('auth/refreshToken', {}).then(res => {
          if (res.status === 200) {
            updateTokenAndUser(
              res.headers.authorization,
              res.data.data.usuarioLogado,
            );
            originalRequest.headers.authorization = `Bearer ${res.headers.authorization}`;
            return api(originalRequest);
          }
          history.push('/');
          return undefined;
        });
      }
      console.error(
        'Erro interno - ',
        error?.response?.data,
        error?.response?.data?.message,
        error?.response?.data?.data?.stack,
      );
      return Promise.reject(error);
    },
  );

  const signOut = useCallback(() => {
    const lembreDeMim = localStorage.getItem('@Connsult:lembreDeMim');

    const deveLembrarDoUsuarioLogado = lembreDeMim
      ? JSON.parse(lembreDeMim)
      : false;

    if (deveLembrarDoUsuarioLogado) {
      localStorage.setItem('@Connsult:tokenUsuarioLembreDeMim', data.token);
      localStorage.setItem(
        '@Connsult:usuarioLembreDeMim',
        JSON.stringify(data.user),
      );
    }

    localStorage.removeItem('@Connsult:token');
    localStorage.removeItem('@Connsult:user');
    setData({} as AuthState);
  }, [data.token, data.user]);

  const signOutUsuarioLembrado = useCallback(() => {
    localStorage.removeItem('@Connsult:tokenUsuarioLembreDeMim');
    localStorage.removeItem('@Connsult:usuarioLembreDeMim');
    localStorage.removeItem('@Connsult:lembreDeMim');
  }, []);

  const signIn = useCallback(
    async (lembreDeMim, apiPostFunction: () => Promise<AxiosResponse>) => {
      const response: AxiosResponse = await apiPostFunction();

      const user = response.data.data.usuarioLogado;
      const token = response.headers.authorization;

      localStorage.setItem('@Connsult:token', token);
      localStorage.setItem('@Connsult:user', JSON.stringify(user));

      if (lembreDeMim) {
        localStorage.setItem('@Connsult:lembreDeMim', JSON.stringify(true));
      }

      api.defaults.headers.authorization = `Bearer ${token}`;

      setData({ token, user });
    },
    [],
  );

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem('@Connsult:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [data.token],
  );

  const updateTokenAndUser = useCallback((token: string, user: User) => {
    localStorage.setItem('@Connsult:user', JSON.stringify(user));
    localStorage.setItem('@Connsult:token', token);

    setData({
      token,
      user,
    });
    api.defaults.headers.authorization = `Bearer ${token}`;
  }, []);

  const forgotPassword = async (
    email: string,
    instituicao?: string,
  ): Promise<AxiosResponse> => {
    return api.post('/auth/esqueceuSenha', { email, instituicao });
  };

  const refreshToken = useCallback(async (): Promise<void> => {
    const res = await api.post('auth/refreshToken', {});
    if (res.status === 200) {
      updateTokenAndUser(
        res.headers.authorization,
        res.data.data.usuarioLogado,
      );
    }
  }, [updateTokenAndUser]);

  const usuarioLogadoPossuiFuncionalidade = useCallback(
    (funcionlidadeParaVerificar: string): boolean => {
      return data.user.funcionalidades.includes(funcionlidadeParaVerificar);
    },
    [data],
  );

  const usuarioLogadoContemPermissaoAdministrador = useCallback((): boolean => {
    return usuarioLogadoPossuiFuncionalidade('ADMINISTRACAO');
  }, [usuarioLogadoPossuiFuncionalidade]);

  const usuarioLogadoPeloLembreDeMim = useCallback((): User | undefined => {
    const usuario = localStorage.getItem('@Connsult:usuarioLembreDeMim');
    if (usuario) {
      return JSON.parse(usuario);
    }

    return undefined;
  }, []);

  const converteUsuarioLembradoEmUsuarioLogado = useCallback(():
    | User
    | undefined => {
    const usuario = usuarioLogadoPeloLembreDeMim();
    const token = localStorage.getItem('@Connsult:tokenUsuarioLembreDeMim');
    if (usuario && token) {
      updateTokenAndUser(token, usuario);
    }

    return usuario;
  }, [updateTokenAndUser, usuarioLogadoPeloLembreDeMim]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        usuarioLogadoPeloLembreDeMim,
        signIn,
        signOut,
        signOutUsuarioLembrado,
        converteUsuarioLembradoEmUsuarioLogado,
        updateUser,
        forgotPassword,
        refreshToken,
        usuarioLogadoContemPermissaoAdministrador,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
