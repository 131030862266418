const en_us = {
  'validation.required': 'Required field',
  'validation.email': 'Invalid email',
  'validation.cpf': 'Digite um CPF no seguinte formato: 999.999.999-99',
  'validation.cnpj': 'Digite um CNPJ no seguinte formato: 99.999.999/9999-99',
  'validation.cep': 'Digite um CEP no seguinte formato: 99.999-999',
  'validation.date.atLeastCurrentDate': 'Somente a partir de hoje',
  'validation.quantidadeMinimaDeCaracteres': 'Quantidade mínima, 20 caracteres',
  'label.sim': 'Yes',
  'label.nao': 'No',
  'label.ativo': 'Active',
  'label.email': 'Email',
  'label.password': 'Password',
  'label.login': 'Access',
  'label.sair': 'Exit',
  'label.trocarSenha': 'Change password',
  'label.justifiqueSuaConfirmacao': 'Justify your confirmation...',
  'label.euAceitoOs': 'I accept the ',
  'label.termosDeServico': 'Service terms',
  'label.consultor': 'Consultant',
  'label.conselheiro': 'Counselor',
  'label.nomeArquivo': 'File Name',
  'label.tipoUsuario': 'User type',
  'label.tipoUsuario.cliente': 'Customer',
  'label.tipoUsuario.consultor': 'Consultant / Administrator',
  'label.dataEHora': 'Date and Time',
  'label.dataEHoraUpload': 'Upload Date and Time',
  'label.esqueceuSenha': 'Forgot your password?',
  'label.conta': 'Account',
  'label.temasESubTemas': 'Themes / Subthemes',
  'label.lembreDeMim': 'Remember password',
  'label.acessar': 'Access',
  'label.trocarUsuario': 'Change use',
  'label.validacao': 'Validation',
  'label.telefone': 'Phone Number',
  'label.editar': 'Edit',
  'label.novo': 'New',
  'label.visualizar': 'View',
  'funcionalidade.agendarAtendimento': 'Schedule Consultation',
  'login.erroAutenticacao.titulo': 'Access failure',
  'login.erroAutenticacao.mensagem':
    'Username or password is invalid. Please check that your email and password are entered correctly.',
  'grupo.administracao': 'Management',
  'grupo.eventos': 'Events',
  'grupo.principal': 'Main',
  'grupo.leanGovernance': 'Lean Governance',
  'grupo.suporte': 'Support',
  'grupo.exemplos': 'Examples',
  'funcionalidade.temas': 'Themes',
  'funcionalidade.usuarios': 'Users',
  'funcionalidade.configuracoes': 'Settings',
  'funcionalidade.perfil': 'Profile',
  'funcionalidade.agenda': 'My agenda',
  'funcionalidade.agenda.cancelamentoComSucesso':
    'Schedule canceled successfully',
  'funcionalidade.agenda.remover': 'Remove schedule',
  'funcionalidade.agenda.cancelar': 'Cancel schedule',
  'funcionalidade.agenda.cancelar.confirmaManterAgenda':
    'Keep schedule as available ?',
  'funcionalidade.agenda.cancelar.mensagemManterAgenda':
    'Do you want to keep the time of  {{horarioInicioEFim}} as available in the calendar after cancellation ?',
  'funcionalidade.agenda.incluirCliente': 'Include customer',
  'funcionalidade.agenda.removerCliente': 'Remove customer',
  'funcionalidade.agenda.confirmarCadastrarEReservar':
    'This action will register and reserve the days agenda {{data}}.',
  'funcionalidade.agenda.desejaRemover':
    'Would you like to confirm the removal of this calendar ?',
  'funcionalidade.agenda.desejaCancelar':
    'Deseja confirmar o cancelamento desta agenda ?',
  'funcionalidade.agenda.desejaReservar':
    'Would you like to confirm the cancellation of this schedule ?',
  'funcionalidade.agenda.sucessoAoSalvar': 'Calendar successfully registered',
  'funcionalidade.agenda.minimoDeMinutosAntecedenciaReservaExcedido':
    'Minimum advance booking time exceeded. The registered limit is {{tempoMinimo}} minutes',
  'funcionalidade.agenda.minimoDeMinutosAntecedenciaCancelamentoExcedido':
    'Minimum cancellation notice time exceeded. The registered limit is {{tempoMinimo}} minutes',
  'funcionalidade.agenda.avisoDiaNaoUtil': 'Not working day',
  'funcionalidade.agenda.confirmarDiaNaoUtilNaAgenda':
    'Heads up! This date is not a business day, you want to confirm the appointment?',
  'funcionalidade.atendimentos.validacaoComSucesso':
    'Service validated successfully',
  'funcionalidade.atendimentos': 'Calls',
  'funcionalidade.atendimentos.confirmacaoDoAgendamento':
    'Appointment confirmation',
  'funcionalidade.profissional.confirmarInativacao':
    'Do you really want to inactivate the professional "{{nomeRegistro}}"?',
  'funcionalidade.atendimentos.agenda.dataEHorarioJaCancelados':
    'Service has already been canceled.',
  'funcionalidade.atendimentos.confirmarAgendamento':
    'Confirm the appointment of the professional"\'"s availability {{profissional}} in day {{dia}} during the period of {{horarioInicio}} until {{horarioFim}} hours.',
  'funcionalidade.atendimentos.sucessoAoAgendar': 'Scheduling successful',
  'funcionalidade.atendimentos.agenda.dataEHorarioJaReservados':
    'Date and Time already booked by someone else.',
  'funcionalidade.atendimentos.atendimentosAPartirDe': 'Calls from: {{data}}',
  'funcionalidade.atendimentos.consultarValidacao': 'Consult validation',
  'funcionalidade.profissional.agendaEDisponibilidade':
    'Professional schedule and availability',
  'funcionalidade.profissional.cadastrarAgenda': 'Register schedule',
  'funcionalidade.profissional.editarAgenda': 'Edit schedule',
  'funcionalidade.profissional.novaAgenda': 'New schedule',
  'funcionalidade.profissional.convidar': 'New Guest',
  'funcionalidade.profissional.horarioReservadoParaOCliente':
    'Reservation for the customer {{cliente}}',
  'funcionalidade.profissional.profissionalComAtendimentosPendentes':
    'The professional {{nome}} cannot be inactivated, as it contains appointments pending validation and/or scheduled with clients.',
  'funcionalidade.profissional.profissionalComDisponibilidadesEmAberto':
    'The professional {{nome}} cannot be inactivated, as it contains an agenda available for clients that needs to be removed in advance.',
  'label.adicionarCliente': 'Add customer',
  'label.novoProfissional': 'New professional',
  'label.duracaoEmHoras': 'Duration hours',
  'label.nome': 'Name',
  'label.codigoExterno': 'External Code',
  'label.cliente': 'Customer',
  'label.empresa': 'Company',
  'label.subTemas': 'Subthemes',
  'label.acoes': 'Actions',
  'label.alterar': 'Change',
  'label.remover': 'Remove',
  'label.adicionar': 'Add',
  'label.salvar': 'Save',
  'label.cancelar': 'Cancel',
  'label.agendar': 'Schedule',
  'label.reagendar': 'Reschedule',
  'label.convidar': 'Invite',
  'label.disponibilizar': 'Provide',
  'label.reservar': 'Reserve',
  'label.reservadoComCliente': 'Booked with customer "{{cliente}}"',
  'label.disponivel': 'Available',
  'label.horarioDeInicio': 'Start time',
  'label.horarioFim': 'End time',
  'label.tipoAcao': 'Type of action',
  'label.digiteNomeOuCpf': 'enter name or document',
  'label.digiteNome': 'Enter the name',
  'modal.confirmarExclusao.mensagem':
    'Do you really want to remove the registry "{{nomeRegistro}}"?',
  'modal.confirmarInativacao.mensagem':
    'Do you really want to inactivate the registry "{{nomeRegistro}}"?',
  'label.domingoAbreviado': 'SUN',
  'label.segundaFeiraAbreviado': 'MON',
  'label.tercaFeiraAbreviado': 'TUE',
  'label.quartaFeiraAbreviado': 'WED',
  'label.quintaFeiraAbreviado': 'THU',
  'label.sextaFeiraAbreviado': 'FRI',
  'label.sabadoAbreviado': 'SAT',
  'label.janeiro': 'January',
  'label.fevereiro': 'February',
  'label.marco': 'March',
  'label.abril': 'April',
  'label.maio': 'May',
  'label.junho': 'June',
  'label.julho': 'July',
  'label.agosto': 'August',
  'label.setembro': 'September',
  'label.outubro': 'October',
  'label.novembro': 'November',
  'label.dezembro': 'December',
  'label.sucesso': 'Success',
  'label.fechar': 'Close',
  'label.periodoDeDatasInformadoInvalido':
    'Period of (date and time) for informed start and end, are invalid',
  'label.disponibilidadeConflitante': 'Conflicting availabilities were found!',
  'label.dataInicioInvalidaInformada': 'Invalid start date and time entered.',
  'label.dataFimInvalidaInformada': 'Invalid end date and time entered',
  'label.naoEPermitidoEditarDisponibilidade':
    'Editing reserved customer agenda is not allowed',
  'mensagem.sucessoAoSalvar': 'Operation performed successfully.',
  'mensagem.erroInterno': 'An internal error has occurred ({{mensagem}})',
  'mensagem.operacacaoRealizadaComSucesso': 'Operation performed successfully',
  'label.falha': 'Failure',
  'label.falhaNoLoginDados': 'Username or password is invalid. Try again!',
  'label.falhaNoLogin': 'Access failure',
  'label.nenhumRegistro': 'No record found',
  'label.credenciado': 'Accredited',
  'label.credenciadoSebrae': 'Accredited Sebrae',
  'label.colaboradorSebrae': 'Collaborator Sebrae',
  'label.inativar': 'Inactivate',
  'label.ativar': 'Activate',
  'modal.confirmarInativacao.titulo': 'Confirm inactivation',
  'modal.confirmarExclusao.titulo': 'Confirm removal',
  'editarTema.incluirSubTemas': 'No subthemes registered. Click (+) to include',
  'label.erro': 'Internal error',
  'mensagem.erroAoSalvar': 'Error saving',
  'perfilProfissional.tempoAtendimento':
    'Calls up to {{tempoEmMinutos}} minutes',
  'label.profissional': 'Profissional',
  'label.profissionais': 'Profissionais',
  'label.biografia': 'Biography',
  'label.formacao': 'Formation',
  'perfilProfissional.titulo': 'Professional profile',
  'funcionalidade.atendimento': 'Attendance',
  'format.date': 'dd/MM/yyyy',
  'format.longDate': "iiii, d 'de' MMMM 'de' yyyy",
  'label.cpf': 'Cpf',
  'label.cnpj': 'Cnpj',
  'label.Empresa': 'Company',
  'label.razaoSocial': 'Corporate Name',
  'label.confirmar': 'Confirm',
  'label.produto': 'Product',
  'label.obrigatorioLeituraEAceiteDosTermos':
    'Mandatory reading and acceptance of the terms',
  'falhaValidacaoDeDominio.isDate':
    'The field "{campo}" should be a valid date',
  'label.copiarAgenda': 'Copy schedule',
  'copiarAgenda.confirmacao':
    'Do you want to copy the schedule for the week of the day {{date}} to the following week?',
  'funcionalidade.buscarProfissional': 'Schedule consultation',
  'label.buscar': 'Search',
  'label.tipo': 'Type',
  'label.aPartirDe': 'From',
  'label.tema': 'Theme',
  'label.status': 'Status',
  'label.duracaoMinutos': 'Duration (Min.)',
  'label.cancelarAtendimento': 'Cancel service',
  'label.linkReuniao': 'Link meeting',
  'label.acessarReuniao': 'Access meeting',
  'label.reuniao': 'Meeting',
  'label.reuniaoProfissional': 'Consultant meeting',
  'label.reuniaoCliente': 'Customer meeting',
  'label.pesquisando': 'Searching...',
  'label.pesquisar': 'Search...',
  'label.enviar': 'Send',
  'label.AGENDADO': 'Scheduled',
  'label.INICIADO': 'Initiated',
  'label.CANCELADO': 'Canceled',
  'label.REAGENDADO': 'Rescheduled',
  'label.CONCLUIDO': 'Concluded',
  'label.VALIDADO': 'Validated',
  'label.VENCIDO': 'Expired',
  'label.PENDENTEVALIDACAO': 'Validation pending',
  'label.CLIENTE_COMPARECEU': 'Customer attended',
  'label.CLIENTE_NAO_COMPARECEU': 'Customer did not attend',
  'label.CONSULTORIA_DISTANCIA': 'Remote consulting',
  'label.ORIENTACAO_TECNICA_DISTANCIA': 'Remote technical guidance',
  'label.minhasConsultorias': 'My consulting',
  'label.copiarUrlReuniao': 'Copy link',
  'label.linkCopiadoComSucesso': 'Link copied successfully',
  'label.aviso': 'Warning',
  'label.comparecimento': 'Attendance',
  'label.descricao': 'Description',
  'label.validar': 'Validate',
  'label.validacaoConsultoria': 'Consulting validation',
  'label.tipoDaConsultoria': 'Type of consultancy',
  'label.subTema': 'Subtheme',
  'label.voltarLogin': 'Back login',
  'funcionalidades.login.reenviarSenha': 'Resend password',
  'funcionalidades.login.esqueceuSenha': 'Forgot your password ?',
  'funcionalidades.login.mensagemReenviarSenha':
    'Please fill in the field with your registered email so that we can send you a temporary access password.',
  'funcionalidades.login.senhaResetada':
    'Password reset successfully. Your new password has been sent to your registered email',
  'funcionalidades.alterarSenha.senhaAtualInvalida': 'Invalid current password',
  'funcionalidades.alterarSenha.sucesso': 'Password changed successfully.',
  'funcionalidades.loginComAlteracaoSenhaObrigatoria':
    'You logged into the system with an automatically generated password. Please Reset Your Password.',
  'funcionalidades.login.emailLoginInvalido':
    'Invalid user entered. Please check that the email has been entered correctly',
  'funcionalidades.login.sebraeMG.erroNaoConhecido':
    'Unknown error, contact support',
  'funcionalidades.login.sebraeMG.clienteNaoEncontradoNaBaseDoSebraeMG':
    'Document not found in the database!',
  'funcionalidades.login.sebraeMG.clienteNaoTemEmailCadastradoNaBaseDoSebraeMG':
    "Document does not have e-mail information in Sebrae's database - MG",
  'funcionalidades.login.sebraeMG.consultorAcessandoComoCliente':
    'Document registered as a consultant, please select the "Consultant" option and enter the registered email and password',
  'funcionalidades.login.sebraeMG.emailClienteRequisitandoEsqueceuSenha':
    'Email registered as a customer, please select the "Customer" option and enter the registered cpf to access.',
  'funcionalidades.login.usuarioSenhaInvalido':
    'Username or password is invalid. Please check that your email and password are entered correctly.',
  'funcionalidades.login.mensagemConfirmacaoReenvioSenha':
    'This action will generate a temporary password that will be sent to the email {{email}} provided. do you wish to continue ?',
  'funcionalidades.alterarSenha.confirmacaoDeSenhaInvalida':
    'Invalid password confirmation',
  'funcionalidade.listarAtendimentos': 'List appointments',
  'label.exportar': 'Export',
  'label.temas': 'Themes',
  'label.minimoMinutosPermitidosAntecedenciaCancelamento':
    'Cancellation notice (minutes)',
  'label.minimoMinutosPermitidosAntecedenciaReserva':
    'Advance booking (minutes)',
  'label.editarPerfil': 'Edit profile',
  'label.visualizarPerfil': 'View profile',
  'funcionalidade.visualizarProfissional': 'My profile',
  'funcionalidade.editarProfissional': 'Change my profile',
  'label.dataDaConsultoria': 'Consulting date',
  'label.alterarFoto': 'Change photo...',
  'label.selecionarFoto': 'Select photo...',
  'label.preVisualizacao': 'Preview',
  'mensagem.arquivoTamanhoMaximo5MB': 'Select files no more than 5MB in size',
  'funcionalidade.editarProfissional.editarFoto': 'Change profile picture',
  'label.genero': 'Genre',
  'genero.FEMININO': 'Female',
  'genero.MASCULINO': 'Male',
  'genero.NAO_INFORMADO': 'Uninformed',
  'label.celular': 'Cell phone',
  'label.alterarSenha': 'Change password',
  'label.senhaAtual': 'Current password',
  'label.novaSenha': 'New password',
  'label.confirmarSenha': 'Confirm password',
  'label.notificacoes': 'Notifications',
  'label.novaNotificacao': 'New notification',
  'label.titulo': 'Title',
  'label.publicada': 'Published',
  'label.notificacao': 'Notification',
  'label.consultores': 'Consultants',
  'label.clientes': 'Customer',
  'label.todos': 'All',
  'label.dataPublicacao': 'Publication date',
  'label.publicar': 'Publish',
  'label.publicarNotificacao': 'Publish notification',
  'label.tipoNotificacao': 'Notification type',
  'mensagem.login.usuarioInativo':
    'Inactive user. Please contact the administrator for correction.',

  'clientes.sebraemg.suporte':
    '<br/><br/><small>Entre em contato com o suporte pelo email <a href="mailto=frederico.arantes@sebreamg.com.br">frederico.arantes@sebraemg.com.br</a> ou ligar no ramal <b>(31) 3314-2801</b>.</small>',

  /* FAQ ************************************************************************** */
  'label.media': 'Score',
  'label.media_desc': 'Average service score',
  'label.comentario': 'Comment',

  /* FAQ ************************************************************************** */
  'label.FAQ': 'FAQ',
  'label.FAQ-Title': 'FAQ List',
  'label.novaFaq': 'New FAQ',
  'label.pergunta': 'Question',
  'label.resposta': 'Answer',
  'label.usuario': 'User',
  'label.id': '#',
  'label.visivelpara': 'Visible to',
  'label.faq-link': 'Link',
  'label.excluir': 'Delete',
  'label.ordem': 'Order',
  'label.exclusaoFaq': 'Deleting the FAQ',
  /* Relatórios ********************************************************************** */
  'grupo.relatorios': 'Reports',
  'label.relatorios.consultorias': 'Consulting',
  'label.relatorios.agendas': 'Agendas',
  /* Lembretes *********************************************************************** */
  'label.lembretes': 'Stick notes',
  'label.novolembrete': 'New stick note',
  'label.lembrete': 'Stick note',
  'label.lembrete.usuario_envio': 'Who sent',
  'label.lembrete.usuario_para': 'Who received',
  'label.lembrete.resposta': 'Answer',
  /* Clientes *********************************************************************** */
  'label.novoCliente': 'New customer',
  'label.cadastrar': 'Register',
  /* Profisionais ******************************************************************* */
  'label.editarProfissional': 'Edit profissional',
  'label.cep': 'CEP',
  'label.logradouro': 'Logradouro',
  'label.numero': 'Número',
  'label.bairro': 'Bairro',
  'label.cidade': 'Cidade',
  'label.estado': 'UF',
  'label.banco': 'Cod. Banco',
  'label.agencia': 'Agência',
  'label.Conta': 'Conta',
  'label.digito': 'Dig.',
  'label.AGUARDANDO_PAGAMENTO': 'Aguardando Pgto',
  /* Eventos ******************************************************************* */
  'label.eventos': 'Events',
  /* Dashboard **************************************************************** */
  'label.dashboard': 'Dashboard',

  /* BinMinas **************************************************************** */
  'label.editarCVB': 'Edit CVB',
  'label.novoCVB': 'New Company/CVB',
  'label.startup': 'Startups',
  'label.cvb': "List of Companies/CVB's",
  'label.tese': 'Investiment Thesis',
  'label.desafios': 'Challenges',
  'label.segmentos': 'Segments',
  'label.website': 'Webiste url',
  'label.nova-startup': 'Click here to register the startup',
  'label.ou': 'or',
  'label.nova-startup': 'Startup Registration',
  'label.nova-cvb': 'CVB Registration',
  'label.founder-nome': 'Founder Name',
  'label.startup-nome': 'Startup Name',
  'label.modelo-negocio': 'Business Model',
  'label.maturidade': 'Maturity Level',
  'label.problemas-resolvidos': 'Problems Solved',
  'label.atencao': 'Attention',
  'label.cadastro-sucesso':
    'Your registration was successful. You will receive an email with instructions for accessing the platform.',
};
export default en_us;
