/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { icons } from './assets/icons/index.js';
import 'bootstrap/dist/css/bootstrap.min.css';

// Trick para fazer o import dos icones indiretamente
// O CIcon usa React.icons[iconName]
React.icons = icons;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
