const pt_br = {
  'validation.required': 'Campo obrigatório',
  'validation.email': 'E-mail inválido',
  'validation.cpf': 'Digite um CPF no seguinte formato: 999.999.999-99',
  'validation.cnpj': 'Digite um CNPJ no seguinte formato: 99.999.999/9999-99',
  'validation.cep': 'Digite um CEP no seguinte formato: 99.999-999',
  'validation.date.atLeastCurrentDate': 'Somente a partir de hoje',
  'validation.quantidadeMinimaDeCaracteres': 'Quantidade mínima, 20 caracteres',
  'label.sim': 'Sim',
  'label.nao': 'Não',
  'label.ativo': 'Ativo',
  'label.email': 'E-mail',
  'label.password': 'Senha',
  'label.login': 'Acessar',
  'label.sair': 'Sair',
  'label.trocarSenha': 'Trocar senha',
  'label.justifiqueSuaConfirmacao': 'Justifique sua confirmação...',
  'label.euAceitoOs': 'Eu aceito os ',
  'label.termosDeServico': 'Termos de Serviço',
  'label.consultor': 'Consultor',
  'label.conselheiro': 'Conselheiro',
  'label.nomeArquivo': 'Nome do Arquivo',
  'label.tipoUsuario': 'Tipo Usuário',
  'label.tipoUsuario.cliente': 'Cliente',
  'label.tipoUsuario.consultor': 'Consultor / Administrador',
  'label.dataEHora': 'Data e hora',
  'label.dataEHoraUpload': 'Data e hora de Upload',
  'label.esqueceuSenha': 'Esqueceu sua senha?',
  'label.conta': 'Conta',
  'label.temasESubTemas': 'Temas / Subtemas',
  'label.lembreDeMim': 'Lembrar senha',
  'label.acessar': 'Acessar',
  'label.trocarUsuario': 'Trocar de usuário',
  'label.validacao': 'Validação',
  'label.telefone': 'Telefone',
  'label.editar': 'Editar',
  'label.novo': 'Novo',
  'label.visualizar': 'Visualizar',
  'funcionalidade.agendarAtendimento': 'Agendar consultoria',
  'login.erroAutenticacao.titulo': 'Falha de acesso',
  'login.erroAutenticacao.mensagem':
    'Usuário ou senha inválidos. Por favor, verifique se o e-mail e senha estão digitados corretamente.',
  'grupo.administracao': 'Administração',
  'grupo.eventos': 'Eventos',
  'grupo.principal': 'Principal',
  'grupo.leanGovernance': 'Lean Governance',
  'grupo.suporte': 'Suporte',
  'grupo.exemplos': 'Exemplos',
  'funcionalidade.temas': 'Temas',
  'funcionalidade.usuarios': 'Usuários',
  'funcionalidade.configuracoes': 'Configurações',
  'funcionalidade.perfil': 'Perfil',
  'funcionalidade.agenda': 'Minha agenda',
  'funcionalidade.agenda.cancelamentoComSucesso':
    'Agenda cancelada com sucesso',
  'funcionalidade.conselho.contratarConselheiro':
    'Conselheiro contratado com sucesso!',
  'funcionalidade.agenda.remover': 'Remover agenda',
  'funcionalidade.agenda.cancelar': 'Cancelar agenda',
  'funcionalidade.agenda.cancelar.confirmaManterAgenda':
    'Manter agenda como disponível ?',
  'funcionalidade.agenda.cancelar.mensagemManterAgenda':
    'Deseja manter o horário de {{horarioInicioEFim}} como disponível na agenda após o cancelamento ?',
  'funcionalidade.agenda.incluirCliente': 'Incluir cliente',
  'funcionalidade.agenda.removerCliente': 'Remover cliente',
  'funcionalidade.agenda.confirmarCadastrarEReservar':
    'Esta ação cadastrará e reservará a agenda do dia {{data}}.',
  'funcionalidade.agenda.desejaRemover':
    'Deseja confirmar a remoção desta agenda ?',
  'funcionalidade.agenda.desejaCancelar':
    'Deseja confirmar o cancelamento desta agenda ?',
  'funcionalidade.agenda.desejaReservar':
    'Deseja confirmar o cadastro com reserva desta agenda ?',
  'funcionalidade.agenda.sucessoAoSalvar': 'Agenda cadastrada com sucesso',
  'funcionalidade.agenda.errorAoSalvar': 'Error ao salvar, tente novamente.',
  'funcionalidade.agenda.minimoDeMinutosAntecedenciaReservaExcedido':
    'Tempo mínimo de antecedência de reserva excedido. O limite cadastrado é de {{tempoMinimo}} minutos',
  'funcionalidade.agenda.minimoDeMinutosAntecedenciaCancelamentoExcedido':
    'Tempo mínimo de antecedência de cancelamento excedido. O limite cadastrado é de {{tempoMinimo}} minutos',
  'funcionalidade.agenda.avisoDiaNaoUtil': 'Aviso dia não útil',
  'funcionalidade.agenda.confirmarDiaNaoUtilNaAgenda':
    'Atenção! Essa data não é dia útil, deseja confirmar o agendamento?',
  'funcionalidade.atendimentos.validacaoComSucesso':
    'Atendimento validado com sucesso',
  'funcionalidade.atendimentos': 'Atendimentos',
  'funcionalidade.atendimentos.confirmacaoDoAgendamento':
    'Confirmação do agendamento',
  'funcionalidade.profissional.confirmarInativacao':
    'Deseja realmente inativar o profissional "{{nomeRegistro}}"?',
  'funcionalidade.atendimentos.agenda.dataEHorarioJaCancelados':
    'Atendimento já foi cancelado.',
  'funcionalidade.atendimentos.confirmarAgendamento':
    'Confirmar o agendamento da disponibilidade do profissional {{profissional}} no dia {{dia}} durante o período de {{horarioInicio}} até {{horarioFim}} horas.',
  'funcionalidade.atendimentos.sucessoAoAgendar':
    'Agendamento realizado com sucesso',
  'funcionalidade.atendimentos.agenda.dataEHorarioJaReservados':
    'Data e Horário já reservados por outra pessoa.',
  'funcionalidade.atendimentos.atendimentosAPartirDe':
    'Atendimentos a partir de: {{data}}',
  'funcionalidade.atendimentos.consultarValidacao': 'Consultar validação',
  'funcionalidade.profissional.agendaEDisponibilidade':
    'Agenda e disponibilidade do profissional',
  'funcionalidade.profissional.cadastrarAgenda': 'Cadastrar agenda',
  'funcionalidade.profissional.editarAgenda': 'Editar agenda',
  'funcionalidade.profissional.novaAgenda': 'Nova agenda',
  'funcionalidade.profissional.convidar': 'Adicionar Convidado',
  'funcionalidade.profissional.horarioReservadoParaOCliente':
    'Reserva para o cliente {{cliente}}',
  'funcionalidade.profissional.profissionalComAtendimentosPendentes':
    'O profissional {{nome}} não pode ser inativado, pois contém atendimentos pendentes de validação e/ou agendados com clientes.',
  'funcionalidade.profissional.profissionalComDisponibilidadesEmAberto':
    'O profissional {{nome}} não pode ser inativado, pois contém agenda disponível para clientes que precisa ser previamente removida.',
  'label.adicionarCliente': 'Adicionar cliente',
  'label.novoProfissional': 'Novo profissional',
  'label.duracaoEmHoras': 'Duração horas',
  'label.nome': 'Nome',
  'label.codigoExterno': 'Código externo',
  'label.cliente': 'Cliente',
  'label.empresa': 'Empresa',
  'label.subTemas': 'Sub-temas',
  'label.acoes': 'Ações',
  'label.alterar': 'Alterar',
  'label.remover': 'Remover',
  'label.adicionar': 'Adicionar',
  'label.salvar': 'Salvar',
  'label.cancelar': 'Cancelar',
  'label.agendar': 'Agendar',
  'label.convidar': 'Convidar',
  'label.reagendar': 'Reagendar',
  'label.disponibilizar': 'Disponibilizar',
  'label.reservar': 'Reservar',
  'label.reservadoComCliente': 'Reservado com cliente "{{cliente}}"',
  'label.disponivel': 'Disponível',
  'label.horarioDeInicio': 'Horário de início',
  'label.horarioFim': 'Horário fim',
  'label.duracao': 'Duração',
  'label.tipoAcao': 'Tipo de ação',
  'label.digiteNomeOuCpf': 'Digite nome ou CPF',
  'label.digiteNome': 'Digite o nome',
  'modal.confirmarExclusao.mensagem':
    'Deseja realmente remover o registro "{{nomeRegistro}}"?',
  'modal.confirmarInativacao.mensagem':
    'Deseja realmente inativar o registro "{{nomeRegistro}}"?',
  'label.domingoAbreviado': 'DOM',
  'label.segundaFeiraAbreviado': 'SEG',
  'label.tercaFeiraAbreviado': 'TER',
  'label.quartaFeiraAbreviado': 'QUA',
  'label.quintaFeiraAbreviado': 'QUI',
  'label.sextaFeiraAbreviado': 'SEX',
  'label.sabadoAbreviado': 'SAB',
  'label.janeiro': 'Janeiro',
  'label.fevereiro': 'Fevereiro',
  'label.marco': 'Março',
  'label.abril': 'Abril',
  'label.maio': 'Maio',
  'label.junho': 'Junho',
  'label.julho': 'Julho',
  'label.agosto': 'Agosto',
  'label.setembro': 'Setembro',
  'label.outubro': 'Outubro',
  'label.novembro': 'Novembro',
  'label.dezembro': 'Dezembro',
  'label.sucesso': 'Sucesso',
  'label.fechar': 'Fechar',
  'label.periodoDeDatasInformadoInvalido':
    'Período de (data e hora) para início e fim informados, estão inválidos',
  'label.disponibilidadeConflitante':
    'Foram encontradas disponibilidades conflitantes!',
  'label.dataInicioInvalidaInformada':
    'Data e hora de início informada inválida.',
  'label.dataFimInvalidaInformada': 'Data e hora de fim informada inválida',
  'label.naoEPermitidoEditarDisponibilidade':
    'Não é permitido editar agenda reservada para cliente',
  'mensagem.sucessoAoSalvar': 'Operação realizada com sucesso.',
  'mensagem.erroInterno': 'Ocorreu um erro interno ({{mensagem}})',
  'mensagem.operacacaoRealizadaComSucesso': 'Operação realizada com sucesso',
  'label.falha': 'Falha',
  'label.falhaNoLogin': 'Falha de acesso',
  'label.falhaNoLoginDados': 'Usuário ou senha inválidos. Tente novamente!',
  'label.nenhumRegistro': 'Nenhum registro encontrado',
  'label.credenciado': 'Credenciado',
  'label.credenciadoSebrae': 'Credenciado Sebrae',
  'label.colaboradorSebrae': 'Colaborador Sebrae',
  'label.inativar': 'Inativar',
  'label.ativar': 'Ativar',
  'modal.confirmarInativacao.titulo': 'Confirmar inativação',
  'modal.confirmarExclusao.titulo': 'Confirmar remoção',
  'editarTema.incluirSubTemas':
    'Nenhum sub tema cadastrado. Clique em (+) para incluir',
  'label.erro': 'Erro interno',
  'mensagem.erroAoSalvar': 'Erro ao salvar',
  'perfilProfissional.tempoAtendimento':
    'Atendimentos de até {{tempoEmMinutos}} minutos',
  'label.profissional': 'Profissional',
  'label.profissionais': 'Profissionais',
  'label.biografia': 'Biografia',
  'label.formacao': 'Formação',
  'label.certificado': 'Certificados',
  'label.onde_orienta': 'Onde pode orientar',
  'label.experiencia': 'Experiência Profissional',
  'perfilProfissional.titulo': 'Perfil profissional',
  'funcionalidade.atendimento': 'Atendimento',
  'format.date': 'dd/MM/yyyy',
  'format.longDate': "iiii, d 'de' MMMM 'de' yyyy",
  'label.cpf': 'Cpf',
  'label.cnpj': 'Cnpj',
  'label.Empresa': 'Empresa',
  'label.razaoSocial': 'Razão Social',
  'label.confirmar': 'Confirmar',
  'label.produto': 'Produto',
  'label.obrigatorioLeituraEAceiteDosTermos':
    'Obrigatório leitura e aceite dos termos',
  'falhaValidacaoDeDominio.isDate':
    'O campo "{campo}" deveria ser uma data válida',
  'label.copiarAgenda': 'Copiar agenda',
  'copiarAgenda.confirmacao':
    'Deseja copiar a agenda da semana do dia {{data}} para a semana seguinte?',
  'funcionalidade.buscarProfissional': 'Agendar consultoria',
  'label.buscar': 'Buscar',
  'label.tipo': 'Tipo',
  'label.aPartirDe': 'A partir de',
  'label.tema': 'Tema',
  'label.status': 'Status',
  'label.duracaoMinutos': 'Duração (Min.)',
  'label.cancelarAtendimento': 'Cancelar atendimento',
  'label.sairDaReuniao': 'Sair da Reunião',
  'label.linkReuniao': 'Link reunião',
  'label.acessarReuniao': 'Acessar reunião',
  'label.reuniao': 'Reunião',
  'label.reuniaoProfissional': 'Reunião Consultor',
  'label.reuniaoCliente': 'Reunião Cliente',
  'label.pesquisando': 'Pesquisando...',
  'label.AGENDADO': 'Agendado',
  'label.INICIADO': 'Iniciado',
  'label.CANCELADO': 'Cancelado',
  'label.REAGENDADO': 'Reagendado',
  'label.CONCLUIDO': 'Concluído',
  'label.VALIDADO': 'Validado',
  'label.VENCIDO': 'Vencido',
  'label.PENDENTEVALIDACAO': 'Pendente de validação',
  'label.CLIENTE_COMPARECEU': 'Cliente compareceu',
  'label.CLIENTE_NAO_COMPARECEU': 'Cliente não compareceu',
  'label.CONSULTORIA_DISTANCIA': 'Consultoria à distância',
  'label.ORIENTACAO_TECNICA_DISTANCIA': 'Orientação técnica à distância',
  'label.minhasConsultorias': 'Minhas Reuniões',
  'label.pesquisar': 'Pesquisar',
  'label.enviar': 'Enviar',
  'label.copiarUrlReuniao': 'Copiar link',
  'label.linkCopiadoComSucesso': 'Link copiado com sucesso',
  'label.aviso': 'Aviso',
  'label.comparecimento': 'Comparecimento',
  'label.descricao': 'Descrição',
  'label.validar': 'Validar',
  'label.validacaoConsultoria': 'Validação consultoria',
  'label.tipoDaConsultoria': 'Tipo da consultoria',
  'label.subTema': 'SubTema',
  'label.voltarLogin': 'Voltar login',
  'funcionalidades.login.reenviarSenha': 'Reenviar senha',
  'funcionalidades.login.esqueceuSenha': 'Esqueceu sua senha ?',
  'funcionalidades.login.mensagemReenviarSenha':
    'Por favor, preencha o campo com o e-mail cadastrado para enviarmos uma senha temporária de acesso.',
  'funcionalidades.login.senhaResetada':
    'Senha resetada com sucesso. Sua nova senha foi enviada para o e-mail cadastrado',
  'funcionalidades.alterarSenha.senhaAtualInvalida': 'Senha atual inválida',
  'funcionalidades.alterarSenha.sucesso': 'Senha alterada com sucesso.',
  'funcionalidades.loginComAlteracaoSenhaObrigatoria':
    'Você acessou o sistema com uma senha gerada automaticamente. Por favor Redefina sua senha.',
  'funcionalidades.login.emailLoginInvalido':
    'Usuário informado inválido. Por favor verifique se o e-mail foi digitado corretamente',
  'funcionalidades.login.sebraeMG.erroNaoConhecido':
    'Erro não conhecido, entre em contato com o suporte',
  'funcionalidades.login.sebraeMG.clienteNaoEncontradoNaBaseDoSebraeMG':
    'CPF não encontrado na base de dados!',
  'funcionalidades.login.sebraeMG.clienteNaoTemEmailCadastradoNaBaseDoSebraeMG':
    'CPF não tem informação de e-mail na base de dados do Sebrae - MG',
  'funcionalidades.login.sebraeMG.consultorAcessandoComoCliente':
    'CPF cadastrado como consultor, por favor, selecione a opção "Consultor" e digite o e-mail e senha cadastrado',
  'funcionalidades.login.sebraeMG.emailClienteRequisitandoEsqueceuSenha':
    'Email cadastrado como cliente, por favor selecione a opção "Cliente" e digite o cpf cadastrado para acessar.',
  'funcionalidades.login.usuarioSenhaInvalido':
    'Usuário ou senha inválidos. Por favor, verifique se o e-mail e senha estão digitados corretamente.',
  'funcionalidades.login.mensagemConfirmacaoReenvioSenha':
    'Esta ação irá gerar uma senha temporária que será enviada para o e-mail {{email}} informado. Deseja continuar ?',
  'funcionalidades.alterarSenha.confirmacaoDeSenhaInvalida':
    'Confirmação de senha inválida',
  'funcionalidade.listarAtendimentos': 'Listar atendimentos',
  'label.exportar': 'Exportar',
  'label.temas': 'Temas',
  'label.minimoMinutosPermitidosAntecedenciaCancelamento':
    'Antecedência para cancelamento (minutos)',
  'label.minimoMinutosPermitidosAntecedenciaReserva':
    'Antecedência para reserva (minutos)',
  'label.editarPerfil': 'Editar perfil',
  'label.visualizarPerfil': 'Visualizar perfil',
  'funcionalidade.visualizarProfissional': 'Meu perfil',
  'funcionalidade.editarProfissional': 'Alterar meu perfil',
  'label.dataDaConsultoria': 'Data da consultoria',
  'label.alterarFoto': 'Alterar foto...',
  'label.selecionarFoto': 'Selecionar foto...',
  'label.preVisualizacao': 'Pré-visualização',
  'mensagem.arquivoTamanhoMaximo5MB':
    'Selecione arquivos com no máximo 5MB de tamanho',
  'funcionalidade.editarProfissional.editarFoto': 'Alterar foto de perfil',
  'label.genero': 'Gênero',
  'genero.FEMININO': 'Feminino',
  'genero.MASCULINO': 'Masculino',
  'genero.NAO_INFORMADO': 'Não informado',
  'label.companyType': 'Tipo de Empresa',
  'companyType.MEI': 'MEI',
  'companyType.LTDA': 'LTDA',
  'companyType.INDIVIDUAL': 'Individual',
  'companyType.NAO_INFORMADO': 'Não informado',
  'label.celular': 'Celular',
  'label.alterarSenha': 'Alterar senha',
  'label.senhaAtual': 'Senha atual',
  'label.novaSenha': 'Nova senha',
  'label.confirmarSenha': 'Confirmar senha',
  'label.notificacoes': 'Notificações',
  'label.novaNotificacao': 'Nova notificação',
  'label.titulo': 'Título',
  'label.publicada': 'Publicada',
  'label.notificacao': 'Notificação',
  'label.consultores': 'Consultores',
  'label.clientes': 'Clientes',
  'label.conselho': 'Conselheiros',
  'label.todos': 'Todos',
  'label.dataPublicacao': 'Data publicação',
  'label.publicar': 'Publicar',
  'label.publicarNotificacao': 'Publicar notificação',
  'label.tipoNotificacao': 'Tipo notificação',
  'mensagem.login.usuarioInativo':
    'Usuário inativo. Por favor procurar o administrador para correção.',

  'clientes.sebraemg.suporte':
    '<br/><br/><small>Entre em contato com o suporte pelo email <a href="mailto=frederico.arantes@sebreamg.com.br">frederico.arantes@sebraemg.com.br</a> ou ligar no ramal <b>(31) 3314-2801</b>.</small>',

  /* FAQ ************************************************************************** */
  'label.media': 'Nota',
  'label.media_desc': 'Nota média de atendimento',
  'label.comentario': 'Comentário',

  /* FAQ ************************************************************************** */
  'label.FAQ': 'FAQ',
  'label.FAQ-Title': 'Lista de FAQ',
  'label.novaFaq': 'Nova FAQ',
  'label.pergunta': 'Pergunta',
  'label.resposta': 'Resposta',
  'label.usuario': 'Usuário',
  'label.id': '#',
  'label.visivelpara': 'Visivel para',
  'label.faq-link': 'Link',
  'label.excluir': 'Excluir',
  'label.ordem': 'Ordem',
  'label.exclusaoFaq': 'Exclusão da FAQ',
  /* Relatórios ********************************************************************** */
  'grupo.relatorios': 'Relatórios',
  'label.relatorios.consultorias': 'Consultorias',
  'label.relatorios.agendas': 'Agendas',
  /* Lembretes *********************************************************************** */
  'label.lembretes': 'Lembretes',
  'label.novolembrete': 'Novo lembrete',
  'label.lembrete': 'Lembrete',
  'label.lembrete.usuario_envio': 'Quem enviou',
  'label.lembrete.usuario_para': 'Quem recebeu',
  'label.lembrete.resposta': 'Resposta',
  /* Clientes *********************************************************************** */
  'label.novoCliente': 'Novo Cliente',
  'label.cadastrar': 'Cadastrar',
  /* Profisionais ******************************************************************* */
  'label.editarProfissional': 'Editar profissional',
  'label.cep': 'CEP',
  'label.logradouro': 'Logradouro',
  'label.numero': 'Número',
  'label.bairro': 'Bairro',
  'label.cidade': 'Cidade',
  'label.estado': 'UF',
  'label.banco': 'Cod. Banco',
  'label.agencia': 'Agência',
  'label.Conta': 'Conta',
  'label.digito': 'Dig.',
  'label.AGUARDANDO_PAGAMENTO': 'Aguardando Pgto',
  /* Eventos ******************************************************************* */
  'label.eventos': 'Eventos',
  /* Dashboard **************************************************************** */
  'label.dashboard': 'Dashboard',

  /* BinMinas **************************************************************** */
  'label.editarCVB': 'Editar CVB',
  'label.novoCVB': 'Nova CVB',
  'label.startup': 'Startups',
  'label.cvb': "Lista de CVB's",
  'label.tese': 'Tese',
  'label.desafios': 'Desafios',
  'label.segmentos': 'Segmentos',
  'label.website': 'Url do site',
  'label.nova-startup': 'Clique aqui para cadastrar a startup',
  'label.ou': 'ou',
};
export default pt_br;
