import React from 'react';
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useHistory } from 'react-router-dom';
import semFoto from '../assets/avatars/semFoto.png';
import { useAuth } from '../hooks/auth';
import i18n from '../i18n/i18n.js';

const TheHeaderDropdown = () => {
  const { signOut, user } = useAuth();
  const history = useHistory();

  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <span className="h6 px-4 mt-2">{user.nome}</span>
        <div className="c-avatar">
          {user.urlAvatar && (
            <CImg
              src={user.urlAvatar}
              className="c-avatar-img"
              alt="admin@bootstrapmaster.com"
            />
          )}
          {!user.urlAvatar && (
            <CImg
              src={semFoto}
              className="c-avatar-img"
              alt="admin@bootstrapmaster.com"
            />
          )}
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem
          onClick={() => {
            history.push('/alterarSenha');
          }}
        >
          <CIcon name="cil-lock-locked" className="mfe-2" />
          {i18n.t('label.trocarSenha')}
        </CDropdownItem>
        <CDropdownItem divider />
        <CDropdownItem onClick={signOut}>
          <CIcon name="cil-account-logout" className="mfe-2" />
          {i18n.t('label.sair')}
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
