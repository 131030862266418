import React, {
  createContext,
  useEffect,
  useCallback,
  useContext,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';
import { Modal, Button } from 'react-bootstrap';
import { AxiosError, AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import i18n from '../i18n/i18n.js';
import 'react-toastify/dist/ReactToastify.css';

export interface AlertMessage {
  id: string;
  tipo?: 'success' | 'error' | 'info' | 'warning';
  titulo: string;
  mensagem?: string;
}

interface AlertContextData {
  addAlert(message: Omit<AlertMessage, 'id'>): void;
  removeAlert(id: string): void;
  handleResponseMessages(reponse: AxiosResponse): void;
}

const AlertContext = createContext<AlertContextData>({} as AlertContextData);

interface ValidationError {
  bundle: string;
  tipo: string;
  parametros: { [key: string]: string };
}

const AlertProvider: React.FC = ({ children }) => {
  const [mensagens, setMensagens] = useState<AlertMessage[]>([]);

  const addAlert = useCallback(
    ({ tipo, titulo, mensagem }: Omit<AlertMessage, 'id'>) => {
      const alert = {
        id: uuid(),
        tipo,
        titulo,
        mensagem,
      } as AlertMessage;

      setMensagens(state => [...state, alert]);
    },
    [],
  );

  const removeAlert = useCallback((id: string) => {
    setMensagens(state => state.filter(mensagem => mensagem.id !== id));
  }, []);

  const isResponse = (
    toBeDetermined: unknown,
  ): toBeDetermined is AxiosResponse => {
    // @ts-nocheck
    if ((toBeDetermined as AxiosResponse).status) {
      return true;
    }
    return false;
  };

  const handleResponseMessages = useCallback(
    (responseOrError: AxiosResponse | AxiosError): void => {
      if (isResponse(responseOrError)) {
        const response = responseOrError as AxiosResponse;
        if (response.data.status === 'success') {
          if (response.data.data.message !== undefined) {
            toast.success(i18n.t(response.data.data.message));
          } else {
            toast.success(i18n.t('mensagem.operacacaoRealizadaComSucesso'));
          }
        }
      } else {
        const error = responseOrError as AxiosError;
        const responseData = error?.response?.data;

        if (responseData && responseData.status === 'fail') {
          responseData.data.falhasDeValidacao.forEach(
            (erroDoResponse: ValidationError) => {
              addAlert({
                titulo: i18n.t('label.falha') as string,
                mensagem: i18n.t(
                  erroDoResponse.bundle,
                  erroDoResponse.parametros,
                ) as string,
                tipo: 'warning',
              });
            },
          );
        } else {
          let mensagemErro = error.message;
          if (responseData) {
            if (responseData.message) {
              mensagemErro += ` - ${responseData.message}`;
            } else {
              mensagemErro += responseData;
            }
          }
          addAlert({
            titulo: i18n.t('label.erro') as string,
            mensagem: i18n.t('mensagem.erroInterno', {
              mensagem: mensagemErro,
            }) as string,
            tipo: 'error',
          });
        }
      }
    },
    [addAlert],
  );

  return (
    <AlertContext.Provider
      value={{ addAlert, removeAlert, handleResponseMessages }}
    >
      {children}
      <AlertContainer alertas={mensagens} />
    </AlertContext.Provider>
  );
};

function useAlert(): AlertContextData {
  const context = useContext(AlertContext);

  if (!context) {
    throw new Error(
      'useAlert so pode ser usado dentro de uma tag AlertProvider',
    );
  }

  return context;
}

/* Container e exibicao das mensagens */

interface AlertContainerProps {
  alertas: AlertMessage[];
}

const AlertContainer: React.FC<AlertContainerProps> = ({ alertas }) => {
  const { removeAlert } = useAlert();
  const [exibirModal, setExibirModal] = useState(false);
  const alerta = exibirModal ? alertas[0] : undefined;

  useEffect(() => {
    setExibirModal(alertas.length > 0);
  }, [alertas]);

  const removerAlertaAtual = (): void => {
    if (alerta) {
      setExibirModal(false);
      setTimeout(() => {
        removeAlert(alerta.id);
      }, 400);
    }
  };

  const modalClass = (_alerta: AlertMessage | undefined): string => {
    if (!_alerta) {
      return '';
    }
    switch (_alerta.tipo) {
      case 'error':
        return 'modal-danger';
      case 'warning':
        return 'modal-warning';
      case 'info':
        return 'modal-info';
      default:
        return '';
    }
  };

  const buttonVariant = (_alerta: AlertMessage | undefined): string => {
    if (!_alerta) {
      return 'primary';
    }
    switch (_alerta.tipo) {
      case 'error':
        return 'outline-danger';
      case 'warning':
        return 'warning';
      default:
        return 'primary';
    }
  };

  return (
    <>
      <ToastContainer />

      <Modal
        className={modalClass(alerta)}
        show={exibirModal}
        onHide={removerAlertaAtual}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{alerta?.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="align-middle"
            dangerouslySetInnerHTML={{ __html: alerta?.mensagem! }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant={buttonVariant(alerta)} onClick={removerAlertaAtual}>
            {i18n.t('label.fechar')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { AlertProvider, AlertContainer, useAlert };
